import React from 'react';
import { Ad } from 'react-ad-manager';

const AdsenseContent = ({ adUnit, name, size }) => {
  return (
    <div style={{ textAlign: 'center' }}>
    <Ad
        adUnit={adUnit}
        name={name}
        size={size}
      />
    </div>
  );
};

export default AdsenseContent;
