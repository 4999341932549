import React from "react";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import AdsenseContent from './AdsenseContent';
import Footer from "./Footer";

export default function AboutUs() {
  return (
    <main>
      <div className="atmequiz_game_page_body">
        <div className="atmequiz_game_page_main">
          <div className="atmequiz_main_page_inner">
            <div className="atmequiz_game_contact_container">
              <div className="atmequiz_game_contact_row">
                <div className="atmequiz_game_contact_wrapper">
                  <div className="atmequiz_game_contact_grid">
                    <h2 className="atmequiz_game_contact_section_title">
                      About Us!
                    </h2>
                    <div className="atmequiz_game_contact_detail_box">
                      <h4>About Us</h4>
                      <p>
                          Welcome to Quizz! We're thrilled to have you join us on this journey of fun, learning, and discovery. Our mission is simple: to create engaging and enjoyable quizzes that challenge your mind and entertain you at the same time.
                      </p>
                    </div>
                    <div className="atmequiz_game_quiz_cato_detail_box">
                      <div className="atmequiz_game_quiz_cato_detail_item">
                            <h5>Who We Are</h5>
                            <p>We’re a team of quiz enthusiasts, trivia buffs, and creative thinkers who believe that learning can be as fun as it is enlightening. With backgrounds in education, entertainment, and content creation, we bring a wealth of experience and passion to every quiz we craft.</p>
                      </div>
                    </div>
                    <div className="atmequiz_game_quiz_cato_detail_box">
                      <div className="atmequiz_game_quiz_cato_detail_item">
                            <h5>What We Do</h5>
                            <p>At Quizz, we design quizzes that cater to a wide range of interests and knowledge levels. From pop culture and history to science and sports, our quizzes are crafted to test your knowledge, spark your curiosity, and maybe even surprise you with something new. Whether you’re a trivia champion or a casual quiz-taker, we have something for everyone.</p>
                      </div>
                    </div>
                    <div className="atmequiz_game_quiz_cato_detail_box">
                      <div className="atmequiz_game_quiz_cato_detail_item">
                            <h5>Our Philosophy</h5>
                            <p>We believe that quizzes should be more than just questions and answers—they should be a fun and interactive way to learn and connect. Our goal is to provide quizzes that are not only challenging but also enjoyable and accessible. We’re committed to delivering high-quality content that makes learning a positive and engaging experience.</p>
                      </div>
                    </div>
                    <div className="atmequiz_game_quiz_cato_detail_box">
                      <div className="atmequiz_game_quiz_cato_detail_item">
                            <h5>Join Us</h5>
                            <p>We invite you to dive in, explore our quizzes, and see how much you can discover. Have feedback or suggestions? We’d love to hear from you! Your input helps us continue to improve and provide quizzes that you’ll love.</p>
                      </div>
                    </div>
                    <p>
                    Thank you for visiting Quizz. Let’s have some fun and learn something new together!
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <AdsenseContent
                   
                    />
                  <Footer/>
        </div>
      </div>
    </main>
  );
}
