import React from "react";
import Footer from "./Footer";
import cricket from "../images/cricket.png";
import coin_icon from "../images/coin-icon.png";
import close_icon from "../images/close_icon.svg";
import google from "../images/google.png";
export default function Login() {
  return (
    <main>
      <div className="atmequiz_game_page_body">
        <div className="atmequiz_game_page_main">
          <div className="atmequiz_main_page_inner">
            <div className="atmequiz_game_contact_container">
              <div className="atmequiz_game_quiz_cato_detail_box">
                <div className="atmequiz_game_quiz_cato_detail_item">
                  <div className="atmequiz_game_quiz_cato_contact_box txt_center">
                    <h3 className="atmequiz_game_quiz_cato_title">
                      Login now & Play Quiz
                    </h3>
                    <div className="atmequiz_game_quiz_cato_desc_box">
                      <p className="atmequiz_game_quiz_cato_desc_text">
                        Play Quizzes and win Coins
                      </p>
                    </div>
                    <div className="atmequiz_game_popup_content">
                      <div className="atmequiz_game_close_div"></div>
                      <div className="atmequiz_game_button_desc_div">
                        <div className="atmequiz_game_button_box flex_align_center js_center">
                          <a
                            href="/"
                            className="atmequiz_game_button_row flex_align_center js_center"
                          >
                            <img src={google} alt="videoIcon" />
                            <span className="atmequiz_game_btn_text">
                              Login
                            </span>
                          </a>
                        </div>
                        <p className="atmequiz_game_desc_text">
                          Don’t have an account? Sign Up
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="atmequiz_game_contact_row">
                <div className="atmequiz_game_contact_wrapper">
                  <div className="atmequiz_game_contact_grid">
                    <h2 className="atmequiz_game_contact_section_title">
                      Play Quiz and Win Coins!
                    </h2>
                    <div className="atmequiz_game_contact_detail_box">
                      <ul className="atmequiz_game_contact_rule_list">
                        <li>
                          <p className="atmequiz_game_contact_list_item">
                            Get coins on the completion of each quiz.
                          </p>
                        </li>
                        <li>
                          <p className="atmequiz_game_contact_list_item">
                            Upgrade your skills with our top quizzes.
                          </p>
                        </li>
                        <li>
                          <p className="atmequiz_game_contact_list_item">
                            We have many top trending categories like Cricket,
                            World, India, Business, loan, insurance & many more!
                          </p>
                        </li>
                        <li>
                          <p className="atmequiz_game_contact_list_item">
                            Millions of quiz admirer like you showed trust on
                            us.
                          </p>
                        </li>
                        <li>
                          <p className="atmequiz_game_contact_list_item">
                            Challenge lakhs of players from across the world!
                          </p>
                        </li>
                        <li>
                          <p className="atmequiz_game_contact_list_item">
                            Challenge lakhs of players from across the world!
                          </p>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer/>
        </div>
      </div>
    </main>
  );
}
