import React, { useState, useEffect } from "react";
import { useLocation, Link } from "react-router-dom";
import back_arrow from "../images/back_arrow.svg";
import logo_1 from "../images/logo_1.png";
import coin_icon from "../images/coin-icon.png";
import close_icon from "../images/close_icon.svg";
import cricket from "../images/cricket.png";
import notifications_icon from "../images/notifications-icon.svg";
import menu_icon from "../images/menu-icon.png";
import flag from "../images/flag.png";
import file from "../images/file.png";
import coins from "../images/coins.png";
import users from "../images/user.png";
import blog from "../images/blog.png";
import about from "../images/about.png";
import customer_service from "../images/customer-service.png";
import "../App.css";
import "../header.js";
import Cookies from 'js-cookie';
import AdsenseContentInter from './AdsenseContent2';


const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isBackArrow, setIsBackArrow] = useState(false);
  const location = useLocation();
  const coinsEarned = parseInt(Cookies.get('coinsEarned') || '0', 10);
  const toggleNav = () => {
    setIsOpen(!isOpen);
  };

  const closeNav = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    setIsBackArrow(location.pathname !== "/");
    setIsBackArrow(location.pathname !== "/Category");
  }, [location]);
  const [quizCompleted, setQuizCompleted] = useState(false);
  const [redirectedFromPlayQuiz, setRedirectedFromPlayQuiz] = useState(false);
  const { state } = location;
  const correctCount = state ? state.correctCount : 0;

  const toggleReportSection = () => {
    const event = new CustomEvent("toggleReportSection", {
      bubbles: true,
      cancelable: true,
    });
    document.dispatchEvent(event);
    closeNav();
  };
  return (
 
    <div>
      <header className="App-header">
      <AdsenseContentInter
    adUnit='/23178317433/kaku_inter_1'
    name='div-gpt-ad-1726128470584-0'
    size={[1024, 768]}
    typeads={'INTERSTITIAL'}
  />

  <AdsenseContentInter
    adUnit='/23178317433/kaku_top_ancher_1'
    name='div-gpt-ad-1726128551196-0'
    size={[[320, 100], [320, 50], [300, 100], [300, 50]]}
    typeads={'TOP_ANCHOR'}
  />
   <AdsenseContentInter
    adUnit='/23178317433/Kaku_StickyAd_1'
    name='div-gpt-ad-1726128644310-0'
    size={[[300, 100], [960, 90], [300, 50], [320, 100], [950, 90], [320, 50], [970, 90]]}
    typeads={'STICKY'}
  />
        <div className="atmequiz_game_header flex_align_center">
          <div className="atmequiz_game_header_wrap">
            <div className="atmequiz_game_header_container">
              <div className={`sidenav ${isOpen ? "open" : ""}`}>
                <div className="header_welcomeProfile__UhPxb">
                  <div className="header_welcomeProfile__UhPxb">
                    <div className="header_profile__hrbFH">
                      <div className="header_profilePic__qVEKN">
                        <img src={users} alt="cricket" />
                      </div>
                      <div className="header_userDetails__SV2aA">
                        <h3>
                          <span className="header_hide__bdeRc">Welcome!</span>
                          <span className="header_contenteditable__Kx6aE">
                            Guest
                          </span>
                        </h3>
                        <p>Play Quiz &amp; earn coins</p>
                        {/* <Link
                          to="/Category"
                          className="header_btn__2sj_6 header_btnSmall__j7DBd header_shine__zk9yn"
                          href="/JoinContest/login"
                        >
                          Play Quizz
                        </Link> */}
                      </div>
                    </div>
                  </div>
                </div>
                <span className="closebtn" onClick={toggleNav}>
                  <img src={close_icon} alt="close" />
                </span>
                <div className="header_menuItems__PRv4k">
                  <ul>
                    <li>
                      <Link to="/Contest-rules/JoinContest" onClick={closeNav}>
                        <i className="header_icon__cvIi5">
                          <img src={file} alt="close" />
                        </i>
                        Contest Rules
                        <i className="header_arrow__M7EL_ header_right__HICCV"></i>
                      </Link>
                    </li>
                    {/* <li>
                      <Link to="/" onClick={closeNav}>
                        <i className="header_icon__cvIi5">
                          <img src={coins} alt="close" />
                        </i>
                        Coin History
                        <i className="header_arrow__M7EL_ header_right__HICCV"></i>
                      </Link>
                    </li> */}
                    {/* <li>
                      <Link to="/" onClick={closeNav}>
                        <i className="header_icon__cvIi5">
                          <img src={blog} alt="close" />
                        </i>
                        Blogs
                        <i className="header_arrow__M7EL_ header_right__HICCV"></i>
                      </Link>
                    </li> */}
                    <li>
                      <Link to="/AboutUs" onClick={closeNav}>
                        <i className="header_icon__cvIi5">
                          <img src={about} alt="close" />
                        </i>
                        About Us
                        <i className="header_arrow__M7EL_ header_right__HICCV"></i>
                      </Link>
                    </li>
                    <li>
                      <Link to="/ContactUs" onClick={closeNav}>
                        <i className="header_icon__cvIi5">
                          <img src={customer_service} alt="close" />
                        </i>
                        Contact Us
                        <i className="header_arrow__M7EL_ header_right__HICCV"></i>
                      </Link>
                    </li>
                     {/*<li>
                    <Link to="/Report" onClick={closeNav}>
                        <i className="header_icon__cvIi5">
                          <img src={flag} alt="close" />
                        </i>
                        Report an Issue
                        <i className="header_arrow__M7EL_ header_right__HICCV"></i>
                      </Link>
                    </li>*/}
                  </ul>
                </div>
              </div>
              <div className="atmequiz_game_header_row">
                <nav className="atmequiz_game_navbar_nav flex_align_center js_between">
                  <span
                    onClick={
                      isBackArrow ? () => window.history.back() : toggleNav
                    }
                  >
                    <img
                      src={isBackArrow ? back_arrow : menu_icon}
                      alt="menu"
                    />
                  </span>
                  <div className="atmequiz_game_navbar_logo_menu flex_align_center">
                    <div className="atmequiz_game_header_logo_div">
                      <Link to="/Category" className="atmequiz_game_header_logo">
                        <img src={logo_1} alt="logo_1" />
                      </Link>
                    </div>
                  </div>
                  <div className="atmequiz_game_navbar_coin_notification flex_align_center">
                    <Link
                      to="/Category"
                      className="atmequiz_game_navbar_coin_with_text"
                    >
                      <div className="atmequiz_game_navbar_coin_div flex_align_center">
                        <div className="atmequiz_game_navbar_coin_img">
                          <img src={coin_icon} alt="coin_icon" />
                        </div>
                        <div className="atmequiz_game_navbar_coin_content">
                          <span className="atmequiz_game_navbar_coin_num">
                          {coinsEarned}
                          </span>
                          <span className="atmequiz_game_navbar_coin_text">
                            Coins
                          </span>
                        </div>
                      </div>
                    </Link>
                    <div className="atmequiz_game_navbar_notifi_div">
                      <Link
                        to="/Category"
                        className="atmequiz_game_navbar_notifi_icon flex_align_center js_center"
                      >
                        <img
                          src={notifications_icon}
                          alt="notifications-icon"
                        />
                      </Link>
                    </div>
                  </div>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </header>
    </div>
  );
};

export default Navbar;
