import React, { useEffect } from "react";
import logo from '../images/logo.png';
import heart_icon from '../images/heart-icon.svg';
import india_flag_icon from '../images/usa.svg';
import { Link } from "react-router-dom";
import AdsenseContent from './AdsenseContent';
export default function Footer() {
 
  return (
    <div className="atmequiz_game_footer">
      <div className="atmequiz_game_footer_inner">
        <div className="atmequiz_game_page_container">
           
          <div className="atmequiz_game_footer_wrap">
            <div className="atmequiz_game_footer_logo">
              <img src={logo} alt="logo"/>
            </div>
            <div className="atmequiz_game_footer_copiright_content">
              <div className="atmequiz_game_footer_copiright_text">
                Made with 
                <img src={heart_icon} alt="heart-icon"/>
                in USA
                <img src={india_flag_icon} alt="india-flag-icon"/>
              </div>

              <Link to="/privacy-policy" >
              Privacy Policy
              </Link> / <Link to="/term-condition" >
                Term Condition
              </Link>
            </div>
            <div  style={{ height: '60px' }}></div>
          </div>
        </div>
        <div class="banner_bottom">
        <div class="adsbygoogle-container">
        <AdsenseContent
                    adUnit="/23178317433/Kakugames_StickyAd-Size"
                    name="div-gpt-ad-1722678943269-0"
                    size={[
                      [320, 50]
                    ]}
                  />
                   </div>
                   </div>
      </div>
    </div>
  );
}