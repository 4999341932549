import React from "react";
import Footer from "./Footer";
import AdsenseContent from './AdsenseContent';
export default function Policy() {
  return (
    <main>
      <div className="atmequiz_game_page_body">
        <div className="atmequiz_game_page_main">
          <div className="atmequiz_main_page_inner">
            <div className="atmequiz_game_contact_container">
              <div className="atmequiz_game_contact_row">
                <div className="atmequiz_game_contact_wrapper">
                  <div className="atmequiz_game_contact_grid">
                    <h5>
                        Privacy Policy and Terms of Use for ku7.kakugames.com
                    </h5><br/>
                    <div className="atmequiz_game_contact_detail_box">
                      <ul className="atmequiz_game_contact_rule_list">
                      <h6>1. Introduction</h6>
                        <li>
                           
                          <p className="atmequiz_game_contact_list_item">
                            Welcome to ku7.kakugames.com! This Privacy Policy and Terms of Use govern your access to and use of our website, 
                            ku7.kakugames.com, and any associated services provided by ku7.kakugames.com
                          </p>
                        </li>
                        <li>
                          <p className="atmequiz_game_contact_list_item">
                                By using our website, you agree to the terms outlined in this policy. 
                                Please review this document thoroughly to understand how we handle, use, and safeguard your information.
                          </p>
                        </li>
                        <h6>2. Data Collection</h6>
                        <li>
                           
                          <p className="atmequiz_game_contact_list_item">
                            We do not collect any personal information or data from users.
                          </p>
                        </li>
                        <h6>3. Use of Data</h6>
                        <li>
                           
                          <p className="atmequiz_game_contact_list_item">
                             Although we do not gather user information, we use data for the following purposes:
                          </p>
                          <p className="atmequiz_game_contact_list_item">
                          – To ensure the smooth operation and maintenance of our website.
                          </p>
                          <p className="atmequiz_game_contact_list_item">
                            – To manage your account and participation in our affiliate partner program.
                          </p>
                          <p className="atmequiz_game_contact_list_item">
                          – To respond to your inquiries, account matters, and provide updates.
                          </p>
                          <p className="atmequiz_game_contact_list_item">
                          – To improve the functionality and overall user experience of our website.
                          </p>
                          <p className="atmequiz_game_contact_list_item">
                          – To meet our legal obligations.
                          </p>
                        </li>
                        <h6>4. Third-Party Services</h6>
                        <li>
                           
                          <p className="atmequiz_game_contact_list_item">
                          We do not permit third parties to directly access or process user data. However, 
                          we may work with third-party payment processors to handle transactions and payments on our 
                          website. Please refer to their privacy policies for additional details.
                          </p>
                        </li>
                        <h6>5. Location of Data Processing</h6>
                        <li>
                           
                          <p className="atmequiz_game_contact_list_item">
                          Data related to our services will be processed in the United States, where our servers are based.
                          </p>
                        </li>
                        <h6>6. Children’s Privacy</h6>
                        <li>
                           
                          <p className="atmequiz_game_contact_list_item">
                            While our website is accessible to children, it is not specifically 
                            designed for those under 13 years of age. We do not knowingly collect 
                            personal information from children under 13. If you become aware that a 
                            child has provided us with their personal data, please contact us so we can promptly delete the information.
                          </p>
                        </li>
                        <h6>6. Children’s Privacy</h6>
                        <li>
                           
                          <p className="atmequiz_game_contact_list_item">
                            While our website is accessible to children, it is not specifically 
                            designed for those under 13 years of age. We do not knowingly collect personal 
                            information from children under 13. If you become aware that a child has provided us
                             with their personal data, please contact us so we can promptly delete the information.
                          </p>
                        </li>
                        <h6>7. Security Measures</h6>
                        <li>
                           
                          <p className="atmequiz_game_contact_list_item">
                          We use reasonable security practices to protect your personal information from unauthorized access, 
                          disclosure, or modification. However, no online data transmission or storage system can be 
                          fully guaranteed as secure. Always exercise caution when sharing your information online.
                          </p>
                          <p className="atmequiz_game_contact_list_item">
                            We do not collect or store any data related to gambling activities. 
                            Our platform does not contain any gambling-related content.
                          </p>
                          <p className="atmequiz_game_contact_list_item">
                            All the coins used in our games are virtual with no real-world value. 
                          </p>
                          <p className="atmequiz_game_contact_list_item">
                             All the games are solely for entertainment purposes. We do not request or collect any payments from users.
                          </p>
                        </li>
                        <p className="atmequiz_game_contact_list_item">Last updated: 01/09/2024</p>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="atmequiz_game_index_page_main">
              <div className="atmequiz_game_index_wrap">
              <AdsenseContent
                     
                    />              </div>
            </div>
          </div>
          <Footer/>
        </div>
      </div>
    </main>
  );
}
