import React from "react";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import contact from "../images/contact.png";
import AdsenseContent from './AdsenseContent';
import Footer from "./Footer";

export default function ContactUs() {
  return (
    <main>
    <div className="atmequiz_game_page_body">
      <div className="atmequiz_game_page_main">
        <div className="atmequiz_main_page_inner">
          <div className="atmequiz_game_contact_container">
            <div className="atmequiz_game_contact_row">
              <div className="atmequiz_game_contact_wrapper">
                <div className="atmequiz_game_contact_grid">
                  <h2 className="atmequiz_game_contact_section_title">
                  Contact Us!
                  </h2>
            <div className="atmequiz_game_quiz_cato_detail_box">
              <div className="atmequiz_game_quiz_cato_detail_item">
                <img src={contact} />
              </div>
            </div>
            <h5>Get in Touch with us</h5>
            <p>For any queries/feedback, please reach us at</p>
            <Link to="/">inquiry@vocsyinfotech.com</Link>
          </div>
          <AdsenseContent
                    
                    />
          <Footer/>
        </div>
      </div>
      </div>
      </div>
      </div>
      </div>
    </main>
  );
}
