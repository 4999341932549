import React, { useState } from "react";
import Footer from "./Footer";
export default function Report() {
  const [selectedProblem, setSelectedProblem] = useState('');
  const [otherProblem, setOtherProblem] = useState('');

  const handleChange = (event) => {
    const value = event.target.value;
    setSelectedProblem(value);
    if (value !== 'Other') {
      setOtherProblem('');
    }
  };

  const handleOtherChange = (event) => {
    setOtherProblem(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const problem = selectedProblem === 'Other' ? otherProblem : selectedProblem;

    try {
      const response = await fetch('http://localhost/quizz/api.php', { 
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: new URLSearchParams({
          method_name: 'report',
          problem: problem,
        }),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok.');
      }

      const result = await response.json();

      if (result.Quizz && Array.isArray(result.Quizz) && result.Quizz[0]) {
        const message = result.Quizz[0].MSG || 'Unexpected response format';
        alert(message);
        if (result.Quizz[0].success === '1') {
          setSelectedProblem('');
          setOtherProblem('');
        }
      } else {
        alert('Unexpected response format');
      }
    } catch (error) {
      console.error('Error:', error);
      alert('There was a problem submitting your report.');
    }
  };

  return (
    <main>
      <div className="atmequiz_game_page_body">
        <div className="atmequiz_game_page_main">
          <div className="atmequiz_main_page_inner">
            <div className="atmequiz_game_contact_container">
              <div className="atmequiz_game_contact_row">
                <div className="atmequiz_game_contact_wrapper">
                  <div className="atmequiz_game_contact_grid">
                    <h2 className="atmequiz_game_contact_section_title">
                      Report an Issue
                    </h2>
                    <p>What kind of problem have you encountered?</p>
                    <form onSubmit={handleSubmit}>
                      <div className="atmequiz_game_contact_detail_box">
                        <select
                          className="select_design"
                          name="problem"
                          value={selectedProblem}
                          onChange={handleChange}
                          required
                        >
                          <option value="" disabled hidden>Select Here</option>
                          <option value="It is not responding">It is not responding</option>
                          <option value="Delayed loading">Delayed loading</option>
                          <option value="Game not responding">Game not responding</option>
                          <option value="Instruction not clear">Instruction not clear</option>
                          <option value="Other">Other</option>
                        </select>
                      </div>
                      {selectedProblem === 'Other' && (
                        <div className="atmequiz_game_contact_detail_box">
                          <textarea
                            type="text"
                            className="textarea_design"
                            placeholder="Please specify"
                            value={otherProblem}
                            onChange={handleOtherChange}
                            required
                          />
                        </div>
                      )}
                      <button type="submit" className="select_button">Submit Report</button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
         
          <Footer/>
        </div>
      </div>
    </main>
  );
}
