import React, { useState, useEffect, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import coin_icon from "../images/coin-icon.png";
import close_icon from "../images/close_icon.svg";
import trophy from "../images/cup.svg";
import winner from "../images/winner.gif";
import quizData from "./quiz_quuestions.json";
import AdsenseContent from "./AdsenseContent";
import Footer from "./Footer";

export default function Category() {
  const [activeCategory, setActiveCategory] = useState("world");
  const quizzes = quizData.data[activeCategory]
    ? quizData.data[activeCategory]
    : {};
  const generateRandomTime = () => {
    const hours = Math.floor(Math.random() * 12);
    const minutes = Math.floor(Math.random() * 60);
    const formattedHours = hours < 10 ? `0${hours}` : `${hours}`;
    const formattedMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;
    const amOrPm = Math.random() < 0.5 ? "am" : "pm";
    return `${formattedHours}:${formattedMinutes} ${amOrPm}`;
  };

  const location = useLocation();
  const [quizCompleted, setQuizCompleted] = useState(false);
  const [redirectedFromPlayQuiz, setRedirectedFromPlayQuiz] = useState(false);
  const { state } = location;
  const correctCount = state ? state.correctCount : 0;
  const wrongCount = state ? state.wrongCount : 0;

  useEffect(() => {
    if (
      state &&
      (state.correctCount !== undefined || state.wrongCount !== undefined)
    ) {
      setQuizCompleted(true);
      if (location.pathname.includes("/join/")) {
        setRedirectedFromPlayQuiz(true);
      }
    }
  }, [state, location]);

  const sliderRef = useRef(null);
  const [isDown, setIsDown] = useState(false);
  const [startX, setStartX] = useState(null);
  const [scrollLeft, setScrollLeft] = useState(null);

  const handleMouseDown = (e) => {
    setIsDown(true);
    sliderRef.current.classList.add("active");
    setStartX(e.pageX - sliderRef.current.offsetLeft);
    setScrollLeft(sliderRef.current.scrollLeft);
  };

  const handleMouseLeave = () => {
    setIsDown(false);
    sliderRef.current.classList.remove("active");
  };

  const handleMouseUp = () => {
    setIsDown(false);
    sliderRef.current.classList.remove("active");
  };

  const handleMouseMove = (e) => {
    if (!isDown) return;
    e.preventDefault();
    const x = e.pageX - sliderRef.current.offsetLeft;
    const walk = (x - startX) * 3;
    sliderRef.current.scrollLeft = scrollLeft - walk;
  };
  const [showReportSection, setShowReportSection] = useState(false);

  useEffect(() => {
    const toggleReportSection = () => {
      setShowReportSection(!showReportSection);
    };
    document.addEventListener("toggleReportSection", toggleReportSection);

    return () => {
      document.removeEventListener("toggleReportSection", toggleReportSection);
    };
  }, [showReportSection]);

  const toggleReportSection = () => {
    setShowReportSection(!showReportSection);
  };

  const [alertMessage, setAlertMessage] = useState("");

  const handleSendFeedback = () => {
    setAlertMessage("Feedback sent successfully!");
    setTimeout(() => {
      setAlertMessage("");
      window.location.href = "Category";
    }, 1000);
  };
  return (
    <main>
      <div className="atmequiz_game_page_body">
        <div className="atmequiz_game_page_main">
          <div className="atmequiz_main_page_inner">
            <div className="atmequiz_game_index_page_main">
              <div className="atmequiz_game_index_wrap">
              <AdsenseContent
                  adUnit="/23178317433/kaku_display_1"
                  name="div-gpt-ad-1726128153154-0"
                  size={[[336, 280], [250, 250], [300, 250]]}
                />
                <br />
                <div className="atmequiz_game_container">
                  <div className="atmequiz_game_row">
                    <div
                      className="atmequiz_game_tab_grid"
                      ref={sliderRef}
                      onMouseDown={handleMouseDown}
                      onMouseLeave={handleMouseLeave}
                      onMouseUp={handleMouseUp}
                      onMouseMove={handleMouseMove}
                    >
                      <ul className="atmequiz_game_tab_grid_list">
                        {Object.keys(quizData.data).map((category, index) => (
                          <li key={index}>
                            <a
                              className={
                                activeCategory === category
                                  ? "atmequiz_game_tab_text active"
                                  : "atmequiz_game_tab_text"
                              }
                              onClick={() => setActiveCategory(category)}
                            >
                              {category}
                            </a>
                          </li>
                        ))}
                      </ul>
                    </div>
                    <div
                      className="atmequiz_quizlist_grid_box tab-active"
                      data-tab="tab_1"
                    >
                      {Object.keys(quizzes).map((quizKey, index) => {
                        const winnerAnnouncementTime = generateRandomTime();
                        const userCount = Math.floor(Math.random() * 10000);
                        return (
                          <div key={index} className="atmequiz_quizlist_card">
                            <div className="atmequiz_quizlist_card_body">
                              <div className="atmequiz_quizlist_card_img flex_align_center js_center">
                                <img
                                  src={`${process.env.PUBLIC_URL}/QuizCategory/${quizzes[quizKey][0].image}`}
                                  className="image_cover"
                                  alt="coin-icon"
                                />
                              </div>
                              <div className="atmequiz_quizlist_content">
                                <div className="atmequiz_quizlist__card_name_live_div flex_align_center js_between">
                                  <span className="atmequiz_quizlist_name">
                                    {quizKey}
                                  </span>
                                  <span className="atmequiz_quizlist_card_line_text">
                                    Live
                                  </span>
                                </div>
                                <div className="atmequiz_quizlist_card_title_coin_row flex_align_center">
                                  <h3 className="atmequiz_quizlist_card_title_text">
                                    Play and Win {quizzes[quizKey][0].win_prize}
                                  </h3>
                                  <div className="atmequiz_quizlist_coin_img">
                                    <img src={coin_icon} alt="coin-icon" />
                                  </div>
                                </div>
                                <p className="atmequiz_quizlist_desc">
                                  Winner announcement @ {winnerAnnouncementTime}
                                </p>
                              </div>
                            </div>
                            <div className="atmequiz_quizlist_card_footer">
                              <p className="atmequiz_quizlist_info">
                                <span className="atmequiz_quizlist_entry_text">
                                  Entry: {quizzes[quizKey][0].coins}
                                </span>
                                <span className="atmequiz_quizlist_info_coin_icon">
                                  <img src={coin_icon} alt="coin-icon" />
                                </span>
                                <span className="atmequiz_quizlist_coin_count">
                                  <strong>{userCount}</strong>
                                </span>
                                <span className="atmequiz_quizlist_user_playing">
                                  Users Playing
                                </span>
                              </p>
                              <Link to={`/join/${activeCategory}/${quizKey}`}>
                                <button className="atmequiz_quizlist_play_button">
                                  Play
                                </button>
                              </Link>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className={`atmequiz_game_prize_body ${
              showReportSection ? "" : "hide"
            }`}
          >
            <div className="atmequiz_game_prize_container">
              <div className="atmequiz_game_prize_detail_box">
                <div className="atmequiz_game_prize_content_grid">
                  {alertMessage && (
                    <div className="alert alert_message">{alertMessage}</div>
                  )}
                  <h1 className="atmequiz_game_prize_content_title tag_fonts_size">
                    Report an Issue
                  </h1>
                  <p>What kind of problem have you encountered?</p>
                  <div className="atmequiz_game_close_div">
                    <button
                      className="atmequiz_game_close_icon"
                      onClick={toggleReportSection}
                    >
                      <img
                        src={close_icon}
                        className="icon_postion"
                        alt="close_icon"
                      />
                    </button>
                  </div>
                  <div className="atmequiz_game_prize_report_box">
                    <div className="atmequiz_game_prize_report_grid">
                      <div className="atmequiz_game_prize_report_list_item">
                        <div className="atmequiz_game_prize_list_row flex_align_center js_between">
                          <div className="atmequiz_game_prize_list_subtitle">
                            <span className="atmequiz_game_prize_list_rank_text d-flex">
                              <label>
                                <input
                                  id="1"
                                  className="customeCheck_radioCustom__QSHkj radio_box"
                                  type="radio"
                                  value="1"
                                  name="issue"
                                />
                                <p className="report_desc">
                                  It's not responding
                                </p>
                              </label>
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="atmequiz_game_prize_report_list_item">
                        <div className="atmequiz_game_prize_list_row flex_align_center js_between">
                          <div className="atmequiz_game_prize_list_subtitle">
                            <span className="atmequiz_game_prize_list_rank_text d-flex">
                              <label>
                                <input
                                  id="1"
                                  className="customeCheck_radioCustom__QSHkj radio_box"
                                  type="radio"
                                  value="1"
                                  name="issue"
                                />
                                <p className="report_desc">
                                  It's stopped working
                                </p>
                              </label>
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="atmequiz_game_prize_report_list_item">
                        <div className="atmequiz_game_prize_list_row flex_align_center js_between">
                          <div className="atmequiz_game_prize_list_subtitle">
                            <span className="atmequiz_game_prize_list_rank_text d-flex">
                              <label>
                                <input
                                  id="1"
                                  className="customeCheck_radioCustom__QSHkj radio_box"
                                  type="radio"
                                  value="1"
                                  name="issue"
                                />
                                <p className="report_desc">Delayed loading</p>
                              </label>
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="atmequiz_game_prize_report_list_item">
                        <div className="atmequiz_game_prize_list_row flex_align_center js_between">
                          <div className="atmequiz_game_prize_list_subtitle">
                            <span className="atmequiz_game_prize_list_rank_text d-flex">
                              <label>
                                <input
                                  id="1"
                                  className="customeCheck_radioCustom__QSHkj radio_box"
                                  type="radio"
                                  value="1"
                                  name="issue"
                                />
                                <p className="report_desc">
                                  Game not responding
                                </p>
                              </label>
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="atmequiz_game_prize_report_list_item">
                        <div className="atmequiz_game_prize_list_row flex_align_center js_between">
                          <div className="atmequiz_game_prize_list_subtitle">
                            <span className="atmequiz_game_prize_list_rank_text d-flex">
                              <label>
                                <input
                                  id="1"
                                  className="customeCheck_radioCustom__QSHkj radio_box"
                                  type="radio"
                                  value="1"
                                  name="issue"
                                />
                                <p className="report_desc">
                                  Instruction not clear
                                </p>
                              </label>
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="atmequiz_game_prize_report_list_item">
                        <div className="atmequiz_game_prize_list_row flex_align_center js_between">
                          <div className="atmequiz_game_prize_list_subtitle">
                            <span className="atmequiz_game_prize_list_rank_text d-flex">
                              <label>
                                <input
                                  id="1"
                                  className="customeCheck_radioCustom__QSHkj radio_box"
                                  type="radio"
                                  value="1"
                                  name="issue"
                                />
                                <p className="report_desc">Other</p>
                              </label>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <button
                    className="atmequiz_game_quiz_cato_join_btn primary_button"
                    onClick={handleSendFeedback}
                  >
                    Send Feedback
                  </button>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </main>
  );
}
