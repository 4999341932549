import React from 'react';
import { Ad } from 'react-ad-manager';

const AdsenseContentInter = ({ adUnit, name, size ,typeads}) => {
  return (
    <div style={{ textAlign: 'center' }}>
     <Ad
        adUnit={adUnit}
        name={name}
        size={size}
        target={[['color', 'red']]}
        refreshTimer={5000}
        type= {typeads}
        eventImpressionViewable={(e) => console.log(e.slot)}
        eventSlotOnload={(e) => console.log(e.slot)}
        eventSlotRenderEnded={(e) => console.log(e.slot)}
        eventSlotRequested={(e) => console.log(e.slot)}
        eventSlotResponseReceived={(e) => console.log(e.slot)}
        eventSlotVisibilityChanged={(e) => console.log(e.slot)}
      />
    </div>
  );
};

export default AdsenseContentInter;
