import React from "react";
import Footer from "./Footer";
import { Link } from "react-router-dom";
import AdsenseContent from "./AdsenseContent";

export default function Policy() {
  return (
    <main>
      <div className="atmequiz_game_page_body">
        <div className="atmequiz_game_page_main">
          <div className="atmequiz_main_page_inner">
            <div className="atmequiz_game_contact_container">
              <div className="atmequiz_game_contact_row">
                <div className="atmequiz_game_contact_wrapper">
                  <div className="atmequiz_game_contact_grid">
                    <h5>
                      Term Condition and Terms of Use for ku7.kakugames.com
                    </h5>
                    <br />
                    <div className="atmequiz_game_contact_detail_box">
                      <ul className="atmequiz_game_contact_rule_list">
                        <h6>1. Introduction</h6>
                        <li>
                          <p className="atmequiz_game_contact_list_item">
                            Welcome to ku7.kakugames.com! By participating in
                            this quiz, you agree to abide by these Terms and
                            Conditions. Please read them carefully.
                          </p>
                        </li>
                        <h6>2. Eligibility</h6>
                        <li>
                          <p className="atmequiz_game_contact_list_item">
                            Participation is open to individuals who are 13 years or older. Employees of
                            ku7.kakugames and their immediate family members
                            are not eligible to participate.
                          </p>
                        </li>
                        <h6>3. Quiz Rules</h6>
                        <li>
                          <p className="atmequiz_game_contact_list_item">
                            The quiz will consist of 10 questions covering
                            world, math and logic, sports, nature and environment, english, business and economics, entertainment, technology, history, travel etc...
                          </p>
                          <p className="atmequiz_game_contact_list_item">
                            Questions must be answered within the specified time
                            limit.
                          </p>
                          <p className="atmequiz_game_contact_list_item">
                            Participants must answer all questions to be
                            eligible for prizes.
                          </p>
                          <p className="atmequiz_game_contact_list_item">
                            – Any attempt to cheat or manipulate the quiz
                            results will result in immediate disqualification.
                          </p>
                        </li>
                        <h6>4. Prizes</h6>
                        <li>
                          <p className="atmequiz_game_contact_list_item">
                            Prizes for the quiz will be describe virtual coin in prizes, if applicable.
                          </p>
                          <p className="atmequiz_game_contact_list_item">
                            Winners will be notified via popup message.
                          </p>
                        </li>
                        <h6>5. Privacy and Data Protection</h6>
                        <li>
                          <p className="atmequiz_game_contact_list_item">
                            Your personal information will be collected and used
                            in accordance with our <Link to="/privacy-policy">Privacy Policy</Link>.
                          </p>
                          <p className="atmequiz_game_contact_list_item">
                            By participating, you consent to the use of your
                            data for quiz administration and promotional
                            purposes.
                          </p>
                        </li>
                        <h6>6. Disqualification </h6>
                        <li>
                          <p className="atmequiz_game_contact_list_item">
                            We reserve the right to disqualify any participant
                            who violates these Terms and Conditions or engages
                            in behavior deemed inappropriate or unfair.
                          </p>
                        </li>
                        <h6>7. Limitation of Liability</h6>
                        <li>
                          <p className="atmequiz_game_contact_list_item">
                            To the extent permitted by law, ku7.kakugames.com
                            shall not be liable for any loss, damage, or injury
                            arising from participation in the quiz or acceptance
                            of any prize.
                          </p>
                        </li>
                        <h6>8. Changes to Terms and Conditions</h6>
                        <li>
                          <p className="atmequiz_game_contact_list_item">
                            We may update these Terms and Conditions from time
                            to time. Participants will be notified of any
                            significant changes.
                          </p>
                        </li>
                        <h6>9. Contact Information</h6>
                        <li>
                          <p className="atmequiz_game_contact_list_item">
                            For any questions or concerns regarding these Terms
                            and Conditions, please contact us at info@babayfiya.com
                          </p>
                        </li>
                        <p className="atmequiz_game_contact_list_item">
                          Last updated: 01/09/2024
                        </p>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </main>
  );
}
