import React from "react";
import Footer from "./Footer";
import AdsenseContent from './AdsenseContent';
export default function Contest_rules() {
  return (
    <main>
      <div className="atmequiz_game_page_body">
        <div className="atmequiz_game_page_main">
          <div className="atmequiz_main_page_inner">
            <div className="atmequiz_game_contact_container">
              <div className="atmequiz_game_contact_row">
                <div className="atmequiz_game_contact_wrapper">
                  <div className="atmequiz_game_contact_grid">
                    <h2 className="atmequiz_game_contact_section_title">
                      Contest Rules!
                    </h2>
                    <div className="atmequiz_game_contact_detail_box">
                      <ul className="atmequiz_game_contact_rule_list">
                        <li>
                          <p className="atmequiz_game_contact_list_item">
                            The winners for each quiz will be declared based on
                            the scores they obtain during the participation in
                            the quiz.
                          </p>
                        </li>
                        <li>
                          <p className="atmequiz_game_contact_list_item">
                            There will be a fixed time for declaring the winners
                            of each quiz.
                          </p>
                        </li>
                        <li>
                          <p className="atmequiz_game_contact_list_item">
                            You will have overall 60 seconds to solve as many as
                            questions from 20 questions in quiz.
                          </p>
                        </li>
                        <li>
                          <p className="atmequiz_game_contact_list_item">
                            There will be 4 options given below each question
                            and one will be the answer for it out of them.
                          </p>
                        </li>
                        <li>
                          <p className="atmequiz_game_contact_list_item">
                            Each right answer fetches you 25 points.
                          </p>
                        </li>
                        <li>
                          <p className="atmequiz_game_contact_list_item">
                            Each wrong answer gives you (-) 10 points.
                          </p>
                        </li>
                        <li>
                          <p className="atmequiz_game_contact_list_item">
                            Do not forget to use the lifelines in case if you
                            are stuck during the contest.
                          </p>
                        </li>
                        <li>
                          <p className="atmequiz_game_contact_list_item">
                            Remember users can use each lifeline once during the
                            each contest. Use a given amount of coins from your
                            coin bank or watch an ad for a few secs to use the
                            lifeline for free!
                          </p>
                        </li>
                        <li>
                          <p className="atmequiz_game_contact_list_item">
                            You would have 4 different lifelines to use:
                          </p>
                          <ul className="atmequiz_game_contact_sublist_box">
                            <li>
                              <p className="atmequiz_game_contact_sublist_text">
                                50:50 – On using it, two incorrect answers will
                                be eliminated from the screen.
                              </p>
                            </li>
                            <li>
                              <p className="atmequiz_game_contact_sublist_text">
                                Freezer Time – A pause for the ongoing timer
                                will take place for 30 seconds while allowing
                                the users get more time to answer the question.
                              </p>
                            </li>
                            <li>
                              <p className="atmequiz_game_contact_sublist_text">
                                Audience Poll – You can use this option to
                                choose the right answer out of 4 options by
                                using the intelligence of the smart audience.
                              </p>
                            </li>
                            <li>
                              <p className="atmequiz_game_contact_sublist_text">
                                Flip Question – A new question will interchange
                                the question currently showing on the screen.
                              </p>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="atmequiz_game_index_page_main">
              <div className="atmequiz_game_index_wrap">
              <AdsenseContent
                     
                    />              </div>
            </div>
          </div>
          <Footer/>
        </div>
      </div>
    </main>
  );
}
